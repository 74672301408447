import React from "react";
import CtaBanner from "../../components/cta-banner";
import Layout from "../../components/layout";
import Review from "../../components/review";
import ServicesHero from "../../components/services/hero";
import Montage from "../../components/services/montage";
import PhotoLeft from "../../components/services/photo-left";
import PhotoRight from "../../components/services/photo-right";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";
import Faq from "../../components/services/faq";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

const FoodPhotographyPage = () => {
	const data = useStaticQuery(graphql`
		query {
			logoImg: file(relativePath: { eq: "logo.png" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP, AVIF]
					)
				}
			}
			foodImg: file(relativePath: { eq: "services/food.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			salmonImg: file(relativePath: { eq: "services/food/salmon.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			waffleImg: file(relativePath: { eq: "services/food/waffles.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			bapImg: file(relativePath: { eq: "services/food/bap.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			rollsMeatImg: file(relativePath: { eq: "services/food/rolls-meat.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			meatBoardImg: file(relativePath: { eq: "services/food/meat-board.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			beefBoardImg: file(relativePath: { eq: "services/food/beefBoard.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			fishImg: file(relativePath: { eq: "services/food/fish.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			gammonImg: file(relativePath: { eq: "services/food/gammon.jpg" }) {
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
			site {
				siteMetadata {
					siteUrl
					company
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const pageUrl = "/services/food-photography";
	const companyName = data.site.siteMetadata.company;
	const schemaImg = data.foodImg.publicURL;
	const logoImg = data.logoImg.publicURL;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Food Photography",
				item: `${siteUrl}/services/food-photography`,
			},
		],
	};

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `${siteUrl}${pageUrl}#food-photographer`,
		image: `${siteUrl}${schemaImg}`,
		mpn: `${siteUrl}`,
		sku: `${siteUrl}${pageUrl}`,
		description:
			"Food Photography is a particular skill. Food needs to look fresh, tasty and jump off the page. I style and shoot in my Eastbourne studio. Click to see my work.",
		logo: `${siteUrl}${logoImg}`,
		name: `${companyName}`,
		url: `${siteUrl}`,
		brand: {
			"@type": "Organization",
			logo: `${siteUrl}${logoImg}`,
			image: `${siteUrl}${schemaImg}`,
			name: `${companyName}`,
			url: `${siteUrl}`,
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: "24",
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: `${siteUrl}/reviews`,
			datePublished: "2021-03-14T17:58:29+00:00",
			reviewBody:
				"Sarah did some great photos for me and it really spruced up my website. Sarah thought about what I said I needed and the result was spot on. Couldn't be happier and would recommend",
			author: {
				"@type": "Person",
				name: "Ian Dove",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: `${companyName}`,
				sameAs: `${siteUrl}`,
			},
		},
	};

	const faqs = [
		{
			question:
				"Can I just supply you with the ingredients and you cook and style it?",
			answer:
				"There are a couple of answers to this question. The first one being yes, you can, however if it’s a dish that is complicated to make, it’s probably better that I bring my mobile studio down to your restaurant and you cook and style as it should look, and I’ll provide the photography. However, if it’s something like cured meats or food items that need to be shot in packaging then I can do this in my studio at home. Supply me with the food and prop foods you’d like in the images and I’ll do the rest.",
		},
		{
			question:
				"What about cutlery, dishes etc, do I need to provide you with those?",
			answer:
				"Again, two possible answers. If your restaurant uses a certain plate, for example a monogramed plate, or a particular colour or design, then please provide the relevant items. If you’re just wanting shots that give customers serving suggestions then I have a huge range of plates, cutlery, dishes and slates I can use in your images.",
		},
		{
			question:
				"I’m not sure how I want my food to be photographed, can you give me some ideas?",
			answer:
				"Of course! We can have a detailed discussion and set up a Pinterest board to share ideas and images of style you like and styles to consider. A kind of mood board if you like. Just call me or email to discuss setting it up.",
		},
	];

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			<GatsbySeo
				title="Food Photographer | Bring your food to life | Sarah Bennett"
				description="Food Photography is a particular skill. Food needs to look fresh, tasty and jump off the page. I style and shoot in my Eastbourne studio. Click to see my work."
				language="en"
				canonical="https://www.sarah-bennett.com/services/food-photography"
				openGraph={{
					url: "https://www.sarah-bennett.com/services/food-photography",
					title:
						"Professional Food Photographer East & West Sussex | Sarah Bennett Commerical Photography",
					description:
						"Food Photography is a particular skill. Food needs to look fresh, tasty and jump off the page. I style and shoot in my Eastbourne studio. Click to see my work.",
					images: [
						{
							url: `${data.foodImg.publicURL}`,
							width: 800,
							height: 600,
							alt: "Experienced Social Media Photographer in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				headline={["Food", <br />, "Photographer"]}
				subheadline=" "
				img={data.foodImg.childImageSharp.gatsbyImageData}
				showBtn={true}
			/>
			<PhotoLeft
				img={data.salmonImg.childImageSharp.gatsbyImageData}
				heading="Tantalise those taste buds"
				text="Do you run a restaurant, café or pub and want to showcase your food and harness the power of visual images to sell your food, or create a buzz on social media? Great food photography is a powerful tool for selling your food with luxurious images that don’t just tempt and tantalise the eyes, but more importantly, the taste buds."
			/>
			<PhotoRight
				img={data.waffleImg.childImageSharp.gatsbyImageData}
				heading="Award Winning Clients"
				text="I work with award winning clients to ensure that their food photos carefully replicate the ambience and dining experience enjoyed by their customers, using either window light or artificial light. Plus props such as beautiful glassware, white plates, fine cutlery and maybe even a roaring fireplace in the background, especially when commissioned to take seasonal photography."
			/>
			<Review
				author="Aneela Rose"
				company="MD Rose Media Group"
				quote="Sarah is a total pleasure to with with; professional, flexible and bursting with ideas."
			/>
			<Montage
				img1={data.beefBoardImg.childImageSharp.gatsbyImageData}
				img2={data.fishImg.childImageSharp.gatsbyImageData}
				img3={data.bapImg.childImageSharp.gatsbyImageData}
				img4={data.gammonImg.childImageSharp.gatsbyImageData}
				img5={data.meatBoardImg.childImageSharp.gatsbyImageData}
			/>
			<CtaBanner
				headline="Call me to find out how I can help you with food photography"
				btnText="Let's Talk"
				url="/contact-us"
			/>
			<PhotoLeft
				img={data.meatBoardImg.childImageSharp.gatsbyImageData}
				heading="Passionate about Local Business"
				text="As a food photographer in Sussex, I love working with locally sourced produce that is found in our great region. From local farm shops supplying dairy and meat to greengrocers, butchers and fishmongers, food photography in Sussex is all about local, local, local."
				cta="Ready to get started?"
				ctaUrl="/contact-us"
			/>
			<PhotoRight
				img={data.rollsMeatImg.childImageSharp.gatsbyImageData}
				heading="Showcase your greatness"
				text={[
					"I work with food manufacturers, eateries and shops who want to use",
					<Link to="/services/product-photography"> product photography </Link>,
					"to market their good food, ensuring that it looks freshly picked or sourced and good enough to eat. If your premises aren’t suitable as a studio, I can use my home studio to create mouth-watering food photography in which the images jump of the page.",
				]}
			/>
			<Container>
				<Row>
					<Col>
						<h2 className="text-start text-lg-center">What I shoot in</h2>
						<p>
							Like many food photographers I shoot in raw format and use macros
							lens for close up detail shots and Bowen lights and bounce light
							to ensure any dark shadows are eliminated. I use different depths
							of field and this ensures you receive interesting, professional
							food shots for food blogs and anything you do that’s based on
							food.
						</p>
					</Col>
				</Row>
			</Container>
			<Review
				quote="We Just wanted to say a huge thank you for getting those fantastic images across to us so quickly. They’re perfect!"
				company="The View Hotel Eastbourne"
				author="Tina Reed"
			/>
			<Faq faqs={faqs} />
			<CtaBanner
				headline="Book an appointment with me to discuss your food photography"
				btnText="Book an appointment"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default FoodPhotographyPage;
