import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const Montage = ({ img1, img2, img3, img4, img5 }) => {
	return (
		<section className="py-6 bg-white">
			<Container>
				<Row className="my-md-3">
					<Col xs={12} md={4} lg={4} className="">
						<GatsbyImage
							image={img1}
							style={{ height: "100%" }}
							objectFit="cover"
							alt="test"
						/>
					</Col>
					<Col xs={12} md={4} lg={4} className="">
						<GatsbyImage
							image={img2}
							style={{ height: "100%" }}
							objectFit="cover"
							alt="test"
						/>
					</Col>
					<Col xs={12} md={4} lg={4} className="">
						<GatsbyImage
							image={img3}
							style={{ height: "100%" }}
							objectFit="cover"
							alt="test"
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} md={6} lg={6} className="">
						<GatsbyImage
							image={img4}
							style={{ height: "100%" }}
							objectFit="cover"
							alt="test"
						/>
					</Col>
					<Col xs={12} md={6} lg={6} className="">
						<GatsbyImage
							image={img5}
							style={{ height: "100%" }}
							objectFit="cover"
							alt="test"
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Montage;
