import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { GatsbyImage } from "gatsby-plugin-image";

const Review = ({ quote, author, company, img }) => {
	const showImage = "";
	const paddingTop = "pt-4";

	if (img === null) {
		showImage = "d-none";
		paddingTop = "pt-1";
	}
	return (
		<section className="py-7 bg-white">
			<Container>
				<Row>
					<Col>
						<Card className={`border-primary ${paddingTop} text-center`}>
							<Card.Img
								className={` mb-2  mx-auto text-center ${showImage}`}
								as={GatsbyImage}
								image={img}
								style={{
									height: "250px",
									borderRadius: "100%",
									width: "250px",
								}}
							/>
							<Card.Body>
								<p className="fw-bold">
									<span className="text-primary">"</span>
									{quote}
									<span className="text-primary">"</span>
								</p>
								<p>
									{author},{` `}
									{company}
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Review;
